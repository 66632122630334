import React from 'react'

function Contact() {
    return (
        <div>
            <h1>Contact Module Upcoming</h1>
            
        </div>
    )
}

export default Contact
